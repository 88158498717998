<template>
  <v-container style="position: relative">
    <PDVContent>
      <v-stepper
        v-model="stepperIndex"
        :vertical="false"
        class="stepper transparent"
        elevation="0"
      >
        <v-stepper-header
          class="stepper-header-custom elevation-3 mb-5 mx-2 rounded"
        >
          <v-stepper-step :key="`1-step`" :complete="stepperIndex > 0" step="1"
            >Escolha o destino</v-stepper-step
          >
          <v-stepper-step :key="`2-step`" :complete="stepperIndex > 1" step="2"
            >{{
              selectedTrips.length > 1
                ? "Selecione a(s) Poltrona(s) da Ida"
                : "Selecione a(s) Poltrona(s)"
            }}
          </v-stepper-step>
          <v-stepper-step
            v-if="selectedTrips.length > 1"
            :key="`3-step`"
            :complete="stepperIndex > 2"
            step="3"
            >Selecione a(s) Poltrona(s) da Volta
          </v-stepper-step>
          <v-stepper-step
            :key="selectedTrips.length > 1 ? '4-step' : '3-step'"
            :complete="stepperIndex > 3"
            :step="selectedTrips.length > 1 ? 4 : 3"
            >Resumo</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content :key="`1-content`" step="1">
            <v-card class="pa-2 mb-1">
              <v-card-text class="pa-0">
                <v-card-title class="font-weight-light subtitle"></v-card-title>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-autocomplete
                      :items="trechos"
                      item-text="nome"
                      item-value="id"
                      label="Origem"
                      prepend-inner-icon="mdi-bus"
                      v-model="search.origem"
                      @change="loadViagens(search)"
                      readonly
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-autocomplete
                      readonly
                      :items="trechos"
                      item-text="nome"
                      item-value="id"
                      label="Destino"
                      prepend-inner-icon="mdi-map-marker"
                      v-model="search.destino"
                      @change="loadViagens(search)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="12" md="2">
                    <v-menu
                      ref="menuCalendarioInicial"
                      v-model="menuCalendarioInicial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(search.data_inicial)"
                          label="Ida"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          @blur="date = parseDate(dateFormatted)"
                          v-on="on"
                          append-icon="mdi-close"
                          @click:append="
                            [(search.data_inicial = null), loadViagens(search)]
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        label="Data Inicial"
                        v-model="search.data_inicial"
                        @input="menuCalendarioInicial = false"
                        @change="loadViagens(search)"
                        :min="currentDate"
                        locale="pt"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="12" md="2">
                    <v-menu
                      ref="menuCalendarioFinal"
                      v-model="menuCalendarioFinal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(search.data_final)"
                          label="Volta"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          @blur="date = parseDate(dateFormatted)"
                          v-on="on"
                          append-icon="mdi-close"
                          @click:append="
                            [(search.data_final = null), loadViagens(search)]
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        label="Data Final"
                        v-model="search.data_final"
                        @input="menuCalendarioFinal = false"
                        @change="loadViagens(search)"
                        :min="search.data_inicial + 1"
                        locale="pt"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <Tickets
              @selectedTravel="selectedTravel"
              :tickets="trips"
              :selectedTravels="selectedTrips"
              class="mt-8"
            />
            <v-footer
              fixed
              color="rgb(12, 120, 209)"
              class="rounded-t footer"
              v-if="selectedTrips.length > 0"
            >
              <div class="flex-grow-1"></div>
              <v-btn
                dark
                depressed
                text
                @click="() => advancePdv(selectedTrips)"
                >Avançar</v-btn
              >
            </v-footer>
          </v-stepper-content>
          <!-- SEGUNDA PARTE -->
          <v-stepper-content :key="`2-content`" step="2">
            <v-card flat>
              <SingleTravelDetails
                v-if="selectedTrips.length > 0"
                @criarPassagemEmPe="criarPassagemEmPe"
                @criarPassagemCrianca="criarPassagemCrianca"
                :data="selectedTrips[0]"
                :tickets="passagens"
                :notBounds="naoVinculadas"
                :selectedTrips="selectedTrips"
                class="mx-2 elevation-3 rounded mb-4"
              />
              <div
                class="elevation-3 mb-2 mx-2 pa-3 rounded d-flex justify-center align-center"
              >
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #c0392b;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Selecionada</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: green;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Livre Para Vendas</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #39ff14;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Melhor Escolha</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #d3d3d3;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Vendida</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #fdba74;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span>Poltrona Indisponível</span>
              </div>
              <Bus
                v-if="selectedTrips.length > 0"
                @criarPassagem="criarPassagem"
                :seats="onibus"
                :selectedTrips="[selectedTrips[0]]"
                :tabindex="0"
              />
              <!-- //PASSGENS TICKETS -->
              <TicketsList
                class="mx-2"
                :clients="clientes"
                :saleTypes="tiposVenda"
                @selectType="selectType"
                @selectClient="selectClient"
                @removeTicket="removerPassagens"
                @checarPassagens="checarPassagens"
                :travels="
                  passagens.filter(
                    (passagem) => passagem.viagem.sentido === 'IDA'
                  )
                "
                @loadClients="loadClientes"
                :isRemarcacao="true"
              />
              <!-- //PASSGENS TICKETS -->
            </v-card>
            <v-footer fixed color="rgb(12, 120, 209)" class="rounded-t footer">
              <v-btn
                dark
                depressed
                text
                :disabled="passagens.length > 0 ? true : false"
                @click="[backStep(), (fPgto = false)]"
                >Anterior</v-btn
              >
              <div class="flex-grow-1"></div>
              <v-label dark color="#fff">{{
                agencia.taxa_embarque > 0
                  ? "Esta Agência possui taxa de embarque acrescentada ao valor da passagem!"
                  : ""
              }}</v-label>
              <div class="flex-grow-1"></div>
              <PDVValue>
                {{ !isNaN(valorTotal) ? valorBrasileiro(valorTotal) : "0,00" }}
              </PDVValue>
              <v-divider vertical></v-divider>
              <v-btn
                v-if="passagens.length > 0 && mostrarTotal"
                dark
                depressed
                text
                @click="
                  [
                    selectedTrips.length > 1
                      ? getBus(selectedTrips[1])
                      : nextStep(),
                    loadSales(),
                    (fPgto = true),
                  ]
                "
                >Avançar</v-btn
              >
            </v-footer>
          </v-stepper-content>
          <v-stepper-content
            v-if="selectedTrips.length > 1"
            :key="`3-content`"
            step="3"
          >
            <v-card flat>
              <SingleTravelDetails
                @criarPassagemEmPe="criarPassagemEmPe"
                @criarPassagemCrianca="criarPassagemCrianca"
                :data="selectedTrips[1]"
                :tickets="passagens"
                :notBounds="naoVinculadas"
                :selectedTrips="[selectedTrips[1]]"
                class="mx-2 elevation-3 rounded mb-4"
              />
              <div
                class="elevation-3 mb-2 mx-2 pa-3 rounded d-flex justify-center align-center"
              >
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #c0392b;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Selecionada</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: green;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Livre Para Vendas</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #39ff14;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Melhor Escolha</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #d3d3d3;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span class="mr-13">Poltrona Vendida</span>
                <div
                  style="
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-color: #fdba74;
                  "
                  class="mr-1 mb-1"
                ></div>
                <span>Poltrona Indisponível</span>
              </div>
              <Bus
                @criarPassagem="criarPassagem"
                :seats="onibus"
                :selectedTrips="[selectedTrips[1]]"
                :tabindex="1"
              />
              <!-- //PASSGENS TICKETS -->
              <TicketsList
                class="mx-2"
                :clients="clientes"
                :saleTypes="tiposVenda"
                @selectType="selectType"
                @selectClient="selectClient"
                @removeTicket="removerPassagens"
                @checarPassagens="checarPassagens"
                :travels="
                  passagens.filter(
                    (passagem) => passagem.viagem.sentido === 'VOLTA'
                  )
                "
                @loadClients="loadClientes"
              />
              <!-- //PASSGENS TICKETS -->
            </v-card>
            <v-footer fixed color="rgb(12, 120, 209)" class="rounded-t footer">
              <v-btn
                dark
                depressed
                text
                :disabled="
                  passagens.filter(
                    (passagem) => passagem.viagem.sentido === 'VOLTA'
                  ).length > 0
                    ? true
                    : false
                "
                @click="
                  [getBus(selectedTrips[0], true), backStep(), (fPgto = false)]
                "
                >Anterior</v-btn
              >
              <div class="flex-grow-1"></div>
              <v-label dark color="#fff">{{
                agencia.taxa_embarque > 0
                  ? "Esta Agência possui taxa de embarque acrescentada ao valor da passagem!"
                  : ""
              }}</v-label>
              <div class="flex-grow-1"></div>
              <PDVValue>
                {{ !isNaN(valorTotal) ? valorBrasileiro(valorTotal) : "0,00" }}
              </PDVValue>
              <v-divider vertical></v-divider>
              <v-btn
                v-if="passagens.length > 0 && mostrarTotal"
                dark
                depressed
                text
                @click="[loadSales(), nextStep(), (fPgto = true)]"
                >Avançar</v-btn
              >
            </v-footer>
          </v-stepper-content>

          <!-- TERCEIRA PARTE -->
          <v-stepper-content
            :key="selectedTrips.length > 1 ? '4-content' : '3-content'"
            :step="selectedTrips.length > 1 ? 4 : 3"
          >
            <swiper
              :slidesPerView="'auto'"
              class="py-1 mx-2"
              :observer="true"
              :observeParents="true"
              :slidesOffsetBefore="2"
              :slidesOffsetAfter="2"
              :space-between="15"
            >
              <swiper-slide
                v-for="(venda, i, a) in vendas"
                :key="a"
                style="width: 240px"
              >
                <CardTravelResume
                  :venda="venda"
                  @removeIndex="removeIndice(i)"
                  v-if="vendas.length > 0 && !venda.viagem.conexao"
                  class="elevation-3"
                />
              </swiper-slide>
            </swiper>

            <div
              class="col-12 text-center d-flex flex-column"
              v-if="!vendas.length"
            >
              <span class="headline">
                Não existem passagens selecionadas no momento.
              </span>
              <v-btn color="primary" text @click="refresh" class="mt-5">
                VOLTAR PARA O INÍCIO
              </v-btn>
            </div>

            <div
              style="
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
              "
              class="mx-2 mb-2"
            >
              <v-card
                outlined
                v-if="vendas.length > 0 && fPgto"
                class="elevation-3"
              >
                <v-toolbar color="primary" dark flat dense>
                  <h3 class="subtitle">Formas de Pagamento</h3>
                </v-toolbar>

                <v-radio-group
                  v-model="formaPagamento"
                  @change="modificarFormaPagamento()"
                  column
                >
                  <v-radio
                    on-icon="mdi-check-circle"
                    off-icon="mdi-checkbox-blank-circle"
                    :label="formaPagamento.descricao.toLowerCase()"
                    color="primary"
                    :value="formaPagamento.id"
                    hide-details
                    v-for="(formaPagamento, i) in formasdePagamento"
                    :key="i"
                    class="text-capitalize"
                  />
                </v-radio-group>
              </v-card>

              <div style="flex: 2" class="ml-5 f-2">
                <v-card
                  outlined
                  class="mb-5 elevation-3"
                  v-if="vendas.length > 0 && fPgto"
                >
                  <v-toolbar color="primary" dark flat dense>
                    <h3 class="subtitle">Valores</h3>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col sm="12" md="12">
                        <ItemResume
                          title="Passagens R$"
                          :value="valorBrasileiro(valorTotal)"
                        />
                      </v-col>
                      <v-col sm="12" md="12">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                          "
                        >
                          <h1 class="fontD">Valor Pago R$:</h1>

                          <div
                            style="
                              width: 80px;
                              margin-left: 5px;
                              position: relative;
                              bottom: 2px;
                            "
                          >
                            <v-text-field
                              dense
                              @keypress="parseFloat(valorPago)"
                              required
                              v-mask-decimal.br="2"
                              v-model="valorPago"
                              class="fontD"
                            />
                          </div>
                        </div>
                      </v-col>
                      <v-col md="12">
                        <h1 class="fontD">
                          <span>Troco R$</span>
                          <span class="primary--text">
                            {{ calculateChange() }}
                          </span>
                        </h1>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  outlined
                  v-if="vendas.length > 0 && fPgto"
                  class="elevation-3"
                >
                  <v-toolbar color="primary" dark flat dense>
                    <h3 class="subtitle">Total Geral</h3>
                  </v-toolbar>
                  <v-card-text
                    class="headline"
                    style="color: #1565c0; font-weight: 600; text-align: right"
                  >
                    R$: {{ valorBrasileiro(valorFinal) }}
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <v-footer
              fixed
              color="rgb(12, 120, 209)"
              class="rounded-t footer"
              v-if="vendas.length > 0"
            >
              <v-btn depressed dark text @click="[backStep(), (fPgto = false)]"
                >Anterior</v-btn
              >
              <div class="flex-grow-1"></div>
              <v-btn text dark @click="enviarVendas()" :disabled="sendBPe"
                >Finalizar</v-btn
              >
            </v-footer>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-snackbar
        v-model="message.snacshow"
        :color="message.type"
        :multi-line="message.mode === 'multi-line'"
        :timeout="message.timeout"
        :vertical="message.mode === 'vertical'"
        bottom
      >
        {{ message.text }}
        <v-btn dark text @click="message.snacshow = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </PDVContent>
    <MessageCashboxOpenToday />
  </v-container>
</template>

<script>
import Helpers from "@/mixins";
import Bus from "./components/Bus";
import CardTravelResume from "@/components/CardTravelResume";
import SingleTravelDetails from "./components/SingleTravelDetails";
// import ConectionTravelDetails from "./components/ConectionTravelDetails";
// import CardSingleTravel from "./components/CardSingleTravel";
// import CardConnectionTravel from "./components/CardConnectionTravel";
import ItemResume from "./components/ItemResume";
import Tickets from "./components/Tickets";
import TicketsList from "./components/TicketCard";
import MessageCashboxOpenToday from "./financeiro/caixa/components/MessageCashboxNoOpenToday";
import { PDVContent, PDVValue } from "./styled";
// import TravelCard from "./components/TravelCard";

import { ticketsData } from "../../models";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";

export default {
  mixins: [Helpers],
  components: {
    Bus,
    Tickets,
    TicketsList,
    // TravelCard,
    PDVContent,
    PDVValue,
    CardTravelResume,
    SingleTravelDetails,
    // ConectionTravelDetails,
    // CardSingleTravel,
    // CardConnectionTravel,
    ItemResume,
    MessageCashboxOpenToday,
    Swiper,
    SwiperSlide,
  },
  props: [
    "id",
    "chBPe",
    "tpAmb",
    "nProt",
    "idOrigem",
    "idDestino",
    "estado",
    "idBpe",
    "idTipoVenda",
    "descTipoVenda",
    "idClient",
  ],
  data() {
    return {
      fPgto: false,
      formaPagamento: "1",
      valorPago: "0",
      valorFinal: 0,
      mostrarTotal: false,
      sentido: "IDA",
      naoVinculadas: false,
      tabs: "#tab-1",
      menuCalendarioInicial: false,
      menuCalendarioFinal: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      id_viagem: 0,
      sendBPe: false,
      pdvCliente: [],
      stepperIndex: 1,
      formasdePagamento: [],
      onibus: [],
      viagem: [],
      linhas: [],
      agencia: 0,
      empresa: 0,
      passagens: [],
      cliente: "",
      clientes: [],
      clienteSelecionado: null,
      tiposServicos: [],
      selectedTrips: [],
      vendas: [],
      trips: [],
      filter: 0,
      trechos: [],
      trechosDestino: [],
      valorTotal: 0.0,
      tipoVenda: 0,
      tiposVenda: [],
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 4000,
      },
      search: {
        origem: null,
        destino: null,
      },
      dados: {
        data_inicial: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        data_final: null,
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      tipoVendaValor: "",
      passagensIda: [],
    };
  },
  created() {
    this.loadAgency();
    this.loadLinhas();
    this.validTrechos();
    this.loadClientes();
    this.loadTipos();
    this.formasPagamento();
    setTimeout(() => {
      this.search.origem = this.idOrigem;
      this.search.destino = this.idDestino;
    }, 500);
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/operadores/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  mounted() {
    this.Helpers;
    this.loadAgency();
    this.loadLinhas();
    this.validTrechos();
    this.loadClientes();
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/operadores/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    "$store.state.agency": {
      handler() {
        this.agencia = this.$store.getters.getAgency;
      },
    },
    stepperIndex: function (newStepper) {
      if (this.selectedTrips.length > 1 && newStepper === 3) {
        this.passagensIda = JSON.parse(JSON.stringify(this.passagens));
      }
    },
  },
  methods: {
    initializeTypeSales(i) {
      for (let index = 0; index < this.tiposVenda.length; index++) {
        if (
          this.tiposVenda[index].tipovenda.descricao == "TARIFA NORMAL" ||
          this.tiposVenda[index].tipovenda.descricao == "PROMOCIONAL"
        ) {
          this.passagens[i].tipoVenda = this.tiposVenda[index].tipovenda.id;
        }
      }
    },
    imprimir() {
      // var ficha = document.getElementById("bilhete");
      // var ventimp = window.open(" ", "popimpr");
      // ventimp.document.write(ficha.innerHTML);
      // ventimp.document.close();
      // ventimp.print();
      // ventimp.close();
      this.$http.post(`/bpe`, this.passagens).then((r) => {
        alert(r.data.result);
        this.$store.dispatch("activeLoader", false);
      });
    },
    removeIndice(index) {
      this.vendas.splice(index, 1);
      this.passagens.splice(index, 1);
      this.calcularTotal();
      this.passagensNaoVinculadas();
    },
    // METODO PARA ATUALIZAR A PAGINA PARA RETORNAR PARA O INICIO **********************via****************************************************************************************
    // CALCULAR TOTAL DA VENDA DE ACORDO COM OS VALORES DE CADA BILHETE SELECIONADO ****************************************************************************************
    calcularTotal() {
      this.valorTotal = 0;
      this.valorFinal = 0;
      for (let index = 0; index < this.passagens.length; index++) {
        this.valorTotal +=
          parseFloat(this.passagens[index].valor) +
          parseFloat(
            !this.passagens[index].viagem.conexao
              ? this.passagens[index].venda.taxaembarque
              : this.passagens[index].vendaTrecho1.taxaembarque
          );
      }
      this.valorFinal = parseFloat(this.valorTotal);
      this.$store.dispatch("activeLoader", false);
      this.loadVendas();
    },
    // FIM DO METODO CALCULAR TOTAL DA VENDA DE ACORDO COM OS VALORES DE CADA BILHETE SELECIONADO ****************************************************************************************
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA *******************
    selectType(indice) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/pdv/tipovendatext/${this.passagens[indice].tipoVenda}`)
        .then((res) => {
          this.tipoVendaValor = res.data[0].descricao;

          if (!this.passagens[indice].viagem.conexao) {
            if (this.tipoVendaValor === "IDOSO 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor / 2;
            } else if (this.tipoVendaValor === "ID JOVEM 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor / 2;
            } else if (this.tipoVendaValor === "CORTESIA") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "DEFICIENTE 100%") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "PROMOCIONAL") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor -
                this.tiposVenda[0].desconto[0].valor;
            } else if (this.tipoVendaValor === "CASADINHA") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor -
                this.tiposVenda[0].desconto[0].valor;
            } else {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor;
            }
          } else {
            this.passagens[indice].viagem.vltotal =
              this.passagens[indice].viagem.vltotalCDesconto > 0
                ? this.passagens[indice].viagem.vltotalCDesconto
                : this.passagens[indice].viagem.vltotal;

            if (this.tipoVendaValor == "IDOSO 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal / 2;
            } else if (this.tipoVendaValor == "ID JOVEM 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal / 2;
            } else if (this.tipoVendaValor == "CORTESIA") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "DEFICIENTE 100%") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor == "PROMOCIONAL") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal -
                this.tiposVenda[0].desconto[0].valor;
            } else if (this.tipoVendaValor == "CASADINHA") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal -
                this.tiposVenda[0].desconto[0].valor;
            } else {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotalCDesconto > 0
                  ? this.passagens[indice].viagem.vltotalCDesconto
                  : this.passagens[indice].viagem.vltotal;
            }
          }
          this.calcularTotal();
        })
        .catch((err) => {
          this.message.text = err;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    // INICIO METODO PARA SELECIONAR O  TEXTO DO TIPO DE VENDA SELECIONADO PARA COMPARAR NO METODO ACIMA PARA DESENVOLVER DESCONTO OU TARIFA *************************************************************************************************************************************
    loadTipoVendaText(id) {
      this.$http
        .get(`/pdv/tipovendatext/${id}`)
        .then((r) => (this.tipoVendaValor = r.data[0].descricao))
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    // FIM DO METODO PARA SELECIONAR O  TEXTO DO TIPO DE VENDA SELECIONADO PARA COMPARAR NO METODO ACIMA PARA DESENVOLVER DESCONTO OU TARIFA ***************************************************************************************************************************************
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA ****************************************************************************************************************************************
    validTrechos() {
      setTimeout(() => {
        this.$http
          .get(`/pdv/localidades/${this.agencia.id_localidade}`)
          .then((r) => {
            this.trechosDestino = r.data;
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "errors";
          });
      }, 500);
    },
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA ****************************************************************************************************************************************
    // METODO PARA SELECIONAR O CLIENTE NA PASSAGEM ****************************************************************************************************************************************
    async selectClient(id) {
      await this.$http
        .get(`/clientes/${id}`)
        .then((r) => {
          this.clienteSelecionado = r.data[0];
          this.loadVendas();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    // FINAL DO METODO PARA SELECIONAR O CLIENTE NA PASSAGEM ****************************************************************************************************************************************
    loadSales() {
      this.vendas = [];
      for (let index = 0; index < this.passagens.length; index++) {
        this.vendas.push(this.passagens[index]);
      }
    },
    // MÉTODO PARA VALIDAR SE A PASSAGEM ESTA COM TIPO DE VENDA E CLIENTE SELECIONADO ****************************************************************************************************************************************
    validarPassagens() {
      // for (let index = 0; index < this.passagens.length; index++) {}
    },
    // MÉTODO PARA VALIDAR SE A PASSAGEM ESTA COM TIPO DE VENDA E CLIENTE SELECIONADO ****************************************************************************************************************************************
    loadTipovendaPDV(desc) {
      this.$http
        .post(`pdv/tipovenda`, { desc })
        .then((r) => {
          this.tipoVenda = r.data[0].id;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    async loadTiposVenda(dados) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/pdv/tipovendas`, dados)
        .then((r) => {
          this.tiposVenda = r.data.filter(
            (value) => value.tipovenda.id !== "9"
          );
          const indexTVTarifa = r.data.findIndex(
            (tv) =>
              tv.tipovenda.descricao == "TARIFA NORMAL" ||
              tv.tipovenda.descricao == "PROMOCIONAL"
          );
          this.tipoVenda =
            this.tiposVenda[indexTVTarifa == -1 ? 0 : indexTVTarifa];
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    criarPassagem(poltrona, viagem) {
      this.$store.dispatch("activeLoader", true);

      this.passagensNaoVinculadas();
      this.blockPoltrona(poltrona);

      this.$http
        .get("/clientes")
        .then((res) => {
          this.cliente = res.data;
          this.validTrechos();

          if (this.passagens.indexOf(this.poltrona) == -1) {
            if (
              poltrona.gratuidade &&
              (poltrona.gratuidade.descricao == "IDOSO 100%" ||
                poltrona.gratuidade.descricao == "DEFICIENTE 100%" ||
                poltrona.gratuidade.descricao == "ID JOVEM 100%") &&
              (poltrona.id_tipo_servico == 1 || poltrona.id_tipo_servico == 2)
            ) {
              this.$http
                .post(`pdv/tipovenda`, { desc: poltrona.gratuidade.descricao })
                .then((r) => {
                  this.tipoVenda = r.data[0].id;
                  this.passagens.push(
                    ticketsData(
                      viagem,
                      this.tipoVenda,
                      poltrona,
                      this.$store.getters.getOpAgency,
                      this.sentido,
                      false,
                      this.idClient
                    )
                  );
                  this.loadTipoVendaText(r.data[0].id);

                  this.passagensNaoVinculadas();
                  this.calcularTotal();
                  this.checarPassagens();
                })
                .catch((e) => {
                  this.message.text = e;
                  this.message.snacshow = true;
                  this.message.type = "success";
                });
            } else {
              //AQUI QUE COMECA A BAGACEIRA
              this.$http
                .post(`/pdv/tipovendas`, {
                  viagens: this.selectedTrips,
                  poltrona: poltrona,
                  agencia: this.$store.getters.getOpAgency,
                  empresa: this.$store.getters.getOpCompany,
                })
                .then((r) => {
                  this.tiposVenda = r.data.filter(
                    (value) => value.tipovenda.id !== "9"
                  );

                  const indexTVTarifa = r.data.findIndex(
                    (tv) => tv.tipovenda.descricao == "TARIFA NORMAL"
                  );

                  let indexTVTarifaEspecial;
                  if (this.idTipoVenda === "7" || this.idTipoVenda === "3") {
                    indexTVTarifaEspecial = r.data.findIndex(
                      (tv) => tv.tipovenda.descricao === "IDOSO 50%"
                    );
                  } else if (
                    this.idTipoVenda === "8" ||
                    this.idTipoVenda === "4"
                  ) {
                    indexTVTarifaEspecial = r.data.findIndex(
                      (tv) => tv.tipovenda.descricao === "ID JOVEM 50%"
                    );
                  }

                  this.tipoVenda =
                    this.tiposVenda[
                      indexTVTarifaEspecial >= 0
                        ? indexTVTarifaEspecial
                        : indexTVTarifa
                    ].tipovenda;

                  this.passagens.push(
                    ticketsData(
                      viagem,
                      this.tipoVenda.id,
                      poltrona,
                      this.$store.getters.getOpAgency,
                      this.sentido,
                      false,
                      this.idClient,
                      this.tipoVenda.descricao
                    )
                  );

                  this.passagensNaoVinculadas();
                  this.calcularTotal();
                  this.checarPassagens();
                  this.selectType(this.passagens.length - 1);
                });
            }
          }
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    criarPassagemEmPe(viagem) {
      this.$store.dispatch("activeLoader", true);

      this.$http
        .get("/clientes")
        .then((res) => {
          this.cliente = res.data;
          this.validTrechos();

          this.$http
            .post(`/pdv/tipovendas`, {
              viagens: viagem,
              poltrona: null,
              agencia: this.$store.getters.getOpAgency,
              empresa: this.$store.getters.getOpCompany,
            })
            .then((r) => {
              this.tiposVenda = r.data.filter(
                (value) => value.tipovenda.id !== "9"
              );
              const indexTVTarifa = r.data.findIndex(
                (tv) => tv.tipovenda.descricao == "TARIFA NORMAL"
              );
              this.tipoVenda =
                this.tiposVenda[
                  indexTVTarifa == -1 ? 0 : indexTVTarifa
                ].tipovenda;

              this.passagens.push(
                ticketsData(
                  viagem,
                  this.tipoVenda.id,
                  null,
                  this.$store.getters.getOpAgency,
                  this.sentido,
                  false,
                  this.idClient,
                  this.tipoVenda.descricao
                )
              );
              this.checarPassagens();
              this.calcularTotal();
            });
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    passagensNaoVinculadas() {
      let criancas = [];
      let criancasValores = [];
      for (let index = 0; index < this.passagens.length; index++) {
        if (this.passagens[index].crianca && !this.passagens[index].emPe) {
          criancas.push(index);
        }
        if (
          !this.passagens[index].passagemVinculada &&
          !this.passagens[index].emPe
        ) {
          criancasValores.push(index);
        }
        if (criancas.length == criancasValores.length && criancas.length > 0) {
          this.naoVinculadas = false;
        } else {
          this.naoVinculadas = true;
        }
      }
    },
    criarPassagemCrianca() {
      this.$store.dispatch("activeLoader", true);

      let passagem = null;
      for (let index = 0; index < this.passagens.length; index++) {
        if (
          !this.passagens[index > 0 ? index - 1 : index].crianca &&
          !this.passagens[index > 0 ? index - 1 : index].emPe
        ) {
          if (
            !this.passagens[index > 0 ? index - 1 : index].passagemVinculada &&
            !this.passagens[index > 0 ? index - 1 : index].emPe
          ) {
            passagem = this.passagens[index > 0 ? index - 1 : index];
          }
        } else if (
          !this.passagens[index].crianca &&
          !this.passagens[index].emPe
        ) {
          if (
            !this.passagens[index].passagemVinculada &&
            !this.passagens[index].emPe
          ) {
            passagem = this.passagens[index];
          }
        }
      }
      if (passagem) {
        passagem.passagemVinculada = true;

        this.$http
          .get("/clientes")
          .then((res) => {
            this.cliente = res.data;
            this.validTrechos();

            this.$http.post(`pdv/tipovenda`, { desc: "CRIANÇA" }).then((r) => {
              this.tipoVenda = r.data[0].id;

              this.passagens.push(
                ticketsData(
                  [passagem.viagem],
                  null,
                  passagem.poltrona,
                  this.$store.getters.getOpAgency,
                  this.sentido,
                  true
                )
              );

              this.passagensNaoVinculadas();
              this.checarPassagens();
            });
          })
          .catch((e) => {
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    removerPassagens(uid) {
      const idx = this.passagens.findIndex((passagem) => passagem.uid === uid);

      if (!this.passagens[idx].crianca && !this.passagens[idx].emPe) {
        this.desbloquearPoltrona(this.passagens[idx].poltrona);
      }

      if (!this.passagens[idx].emPe) {
        for (let index = 0; index < this.passagens.length; index++) {
          if (
            this.passagens[index].poltrona &&
            this.passagens[index].poltrona.numero ==
              this.passagens[idx].poltrona.numero &&
            !this.passagens[index].emPe
          ) {
            this.passagens[index].passagemVinculada = false;
          }
        }
      }

      if (!this.passagens[idx].emPe && !this.passagens[idx].crianca) {
        const childrenTickets = [...this.passagens];
        const checkChildrenTicketIndex = childrenTickets.findIndex(
          (passagem) =>
            passagem.poltronaVinculada === childrenTickets[idx].poltrona.numero
        );
        if (checkChildrenTicketIndex > -1) {
          this.passagens.splice(checkChildrenTicketIndex, 1);
          this.passagens.splice(idx, 1);
        } else {
          this.passagens.splice(idx, 1);
        }
      } else {
        this.passagens.splice(idx, 1);
      }

      this.calcularTotal();
      this.passagensNaoVinculadas();
    },
    nextStep() {
      this.stepperIndex++;
    },
    backStep() {
      this.stepperIndex--;
    },
    loadVendas() {
      for (let index = 0; index < this.passagens.length; index++) {
        if (!this.passagens[index].viagem.conexao) {
          this.passagens[index].venda.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].venda.id_cliente =
            this.passagens[index].cliente;
          this.passagens[index].venda.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].venda.id_localidade_origem =
            this.passagens[index].viagem.origem.id_localidade;
          this.passagens[index].venda.id_localidade_destino =
            this.passagens[index].viagem.destino.id_localidade;
          this.passagens[index].venda.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].venda.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.origem.hora;
          this.passagens[index].venda.id_tarifa =
            this.passagens[index].viagem.tarifa.id;
          this.passagens[index].venda.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].venda.id_formapagamento = "1";
          this.passagens[index].venda.id_linha =
            this.passagens[index].viagem.origem.id_linha;
          if (this.passagens[index].poltrona) {
            this.passagens[index].venda.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].venda.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          }
          // VALORES ***************************************************************************************
          // VALOR  TOTAL ***************************************************************************************
          this.passagens[index].venda.valor_total =
            this.passagens[index].viagem.tarifa.valor;
          // VALOR  TOTAL ***************************************************************************************
          // VALOR A PAGAR ***************************************************************************************
          if (this.agencia.taxa_embarque) {
            this.passagens[index].venda.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].venda.taxaembarque =
              this.agencia.taxa_embarque;
          }
          // VALOR A PAGAR ***************************************************************************************
          // VALOR DO DESCONTO  ***************************************************************************************
          if (this.passagens[index].tipoVenda == 3) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor / 2;
          } else if (this.passagens[index].tipoVenda == 4) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor / 2;
          } else if (this.passagens[index].tipoVenda == 2) {
            this.passagens[index].venda.desconto =
              this.tiposVenda[0].desconto[0].valor;
          } else if (this.passagens[index].tipoVenda == 1) {
            this.passagens[index].venda.desconto =
              this.tiposVenda[0].desconto[0].valor;
            this.passagens[index].venda.taxaembarque = parseFloat(
              this.agencia.taxa_embarque
            );
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].venda.desconto = 0;
          } else {
            this.passagens[index].venda.desconto = 0;
          }

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].venda.taxaembarque = 0;
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor;
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].venda.desconto = 0;
          }

          if (this.passagens[index].crianca) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor;
            this.passagens[index].venda.taxaembarque = 0;
            this.passagens[index].venda.valor_apagar = 0;
          }
        } else {
          // TRECHO 1  ***************************************************************************************
          this.passagens[index].vendaTrecho1.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].vendaTrecho1.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].vendaTrecho1.id_localidade_origem =
            this.passagens[index].viagem[0].id_localidade_origem_inicial;
          this.passagens[index].vendaTrecho1.id_localidade_destino =
            this.passagens[index].viagem[0].id_localidade_destino_inicial;
          this.passagens[index].vendaTrecho1.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].vendaTrecho1.id_linha =
            this.passagens[index].viagem[0].id_linha_inicial;
          this.passagens[index].vendaTrecho1.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.conexao[0].origem_inicial.hora;
          this.passagens[index].vendaTrecho1.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].vendaTrecho1.id_tarifa =
            this.passagens[index].viagem.conexao[0].tarifa.id;
          this.passagens[index].vendaTrecho1.id_formapagamento = "1";
          if (this.passagens[index].poltrona) {
            this.passagens[index].vendaTrecho1.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].vendaTrecho1.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          } else {
            this.passagens[index].vendaTrecho1.id_tiposervico =
              this.passagens[index].viagem.onibus.id_tipo_servico;
          }
          this.passagens[index].vendaTrecho1.id_cliente =
            this.passagens[index].cliente;

          if (this.agencia.taxa_embarque) {
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].vendaTrecho1.taxaembarque =
              this.agencia.taxa_embarque;
          }

          // VALORES TRECHO 1 ***************************************************************************************
          let desconto = this.passagens[index].viagem.desconto_primeira_rota
            ? this.passagens[index].viagem.desconto_primeira_rota
            : 0;

          if (this.passagens[index].tipoVenda == 3) {
            //IDOSO 50%
            let c =
              (this.passagens[index].viagem.conexao[0].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;

            this.passagens[index].vendaTrecho1.valor_apagar = calculada;
            this.passagens[index].vendaTrecho1.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 4) {
            //ESTUDANTE 50%
            let c =
              (this.passagens[index].viagem.conexao[0].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho1.valor_apagar = calculada;
            this.passagens[index].vendaTrecho1.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 6) {
            //CORTESIA
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          } else if (this.passagens[index].tipoVenda == 7) {
            // IDOSO 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 8) {
            // ID JOVEM 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 9) {
            // DEFICIENTE 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 2) {
            // PROMOCIONAL
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_primeira_rota
                ? parseFloat(
                    this.passagens[index].viagem.desconto_primeira_rota
                  )
                : 0);
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[0].tarifa) -
              this.passagens[index].vendaTrecho1.desconto;
          } else if (this.passagens[index].tipoVenda == 1) {
            // CASADINHA
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_primeira_rota
                ? parseFloat(
                    this.passagens[index].viagem.desconto_primeira_rota
                  )
                : 0);
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[0].tarifa) -
              this.passagens[index].vendaTrecho1.desconto;
          } else if (this.passagens[index].tipoVenda == 10) {
            // TARIFA NORMAL
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto = this.passagens[index]
              .viagem.desconto_primeira_rota
              ? parseFloat(this.passagens[index].viagem.desconto_primeira_rota)
              : 0;
            this.passagens[index].vendaTrecho1.valor_apagar = parseFloat(
              this.passagens[index].viagem.desconto_primeira_rota
                ? this.passagens[index].viagem.conexao[0].tarifa -
                    this.passagens[index].viagem.desconto_primeira_rota
                : this.passagens[index].viagem.conexao[0].tarifa
            );
          }

          this.passagens[index].vendaTrecho1.taxaembarque = parseFloat(
            this.agencia.taxa_embarque
          );

          if (this.passagens[index].crianca) {
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
          }

          // this.passagens[index].vendaTrecho1.valor_apagar =
          //   parseFloat(
          //     this.passagens[index].vendaTrecho1.valor_total -
          //       this.passagens[index].vendaTrecho1.desconto
          //   ) +
          //   parseFloat(this.passagens[index].vendaTrecho1.taxaembarque) / 2;

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          }

          // VALORES TRECHO 1 ***************************************************************************************

          // TRECHO 2 ****************************************************************************************
          this.passagens[index].vendaTrecho2.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].vendaTrecho2.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].vendaTrecho2.id_localidade_origem =
            this.passagens[index].viagem[0].id_localidade_origem_final;
          this.passagens[index].vendaTrecho2.id_localidade_destino =
            this.passagens[index].viagem[0].id_localidade_destino_final;
          this.passagens[index].vendaTrecho2.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].vendaTrecho2.id_linha =
            this.passagens[index].viagem[0].id_linha_final;
          this.passagens[index].vendaTrecho2.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.conexao[1].origem_final.hora;
          this.passagens[index].vendaTrecho2.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].vendaTrecho2.id_tarifa =
            this.passagens[index].viagem.conexao[1].tarifa.id;
          this.passagens[index].vendaTrecho2.id_formapagamento = "1";
          if (this.passagens[index].poltrona) {
            this.passagens[index].vendaTrecho2.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].vendaTrecho2.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          } else {
            this.passagens[index].vendaTrecho2.id_tiposervico =
              this.passagens[index].viagem.onibus.id_tipo_servico;
          }
          this.passagens[index].vendaTrecho2.id_cliente =
            this.passagens[index].cliente;

          if (this.agencia.taxa_embarque) {
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].vendaTrecho2.taxaembarque =
              this.agencia.taxa_embarque;
          }

          // VALORES TRECHO 2 ***************************************************************************************
          if (this.passagens[index].tipoVenda == 3) {
            let c =
              (this.passagens[index].viagem.conexao[1].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho2.valor_apagar = calculada;
            this.passagens[index].vendaTrecho2.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 4) {
            let c =
              (this.passagens[index].viagem.conexao[1].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho2.valor_apagar = calculada;
            this.passagens[index].vendaTrecho2.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 6) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          } else if (this.passagens[index].tipoVenda == 7) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 8) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 9) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 2) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_segunda_rota
                ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
                : 0);
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[1].tarifa) -
              this.passagens[index].vendaTrecho2.desconto;
          } else if (this.passagens[index].tipoVenda == 1) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_segunda_rota
                ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
                : 0);
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[1].tarifa) -
              this.passagens[index].vendaTrecho2.desconto;
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto = this.passagens[index]
              .viagem.desconto_segunda_rota
              ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
              : 0;
            this.passagens[index].vendaTrecho2.valor_apagar = parseFloat(
              this.passagens[index].viagem.desconto_segunda_rota
                ? this.passagens[index].viagem.conexao[1].tarifa -
                    this.passagens[index].viagem.desconto_segunda_rota
                : this.passagens[index].viagem.conexao[1].tarifa
            );
          }
          this.passagens[index].vendaTrecho2.taxaembarque = parseFloat(
            this.agencia.taxa_embarque
          );

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.taxaembarque = 0;
          }

          if (this.passagens[index].crianca) {
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.taxaembarque = 0;
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
          }
          // VALORES TRECHO 2 ***************************************************************************************
        }
      }
    },
    async loadClientes() {
      this.$store.dispatch("activeLoader", true);
      await this.$http
        .get(`/clientes`)
        .then((r) => {
          this.clientes = r.data;
          this.validTrechos();
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    modificarFormaPagamento() {
      for (let index = 0; index < this.passagens.length; index++) {
        if (!this.passagens[index].viagem.conexao) {
          this.passagens[index].venda.id_formapagamento = this.formaPagamento;
        } else {
          this.passagens[index].vendaTrecho1.id_formapagamento =
            this.formaPagamento;
          this.passagens[index].vendaTrecho2.id_formapagamento =
            this.formaPagamento;
        }
      }
    },
    blockPoltrona(seats) {
      const enabledPoltrona = this.onibus.findIndex(
        (o) => o.poltrona == seats.poltrona
      );

      this.onibus[enabledPoltrona].disabled = true;
    },
    desbloquearPoltrona(seats) {
      const removePoltrona = this.onibus.findIndex(
        (o) => o.poltrona == seats.poltrona
      );
      this.onibus[removePoltrona].disabled = false;
    },
    loadTipos() {
      this.$http
        .get(`/tiposservico`)
        .then((r) => {
          this.tiposServicos = r.data;
          this.validTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadTrechos() {
      this.$http
        .get(`/pdv/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.validTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadViagem(id) {
      this.$http
        .get(`/pdv/viagens/${id}`)
        .then((r) => {
          this.viagem = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    refresh() {
      location.reload(1);
    },
    loadViagens(search) {
      this.$store.dispatch("activeLoader", true);
      this.onibus = [];
      this.trips = [];

      this.$http
        .post(`/pdv/viagens/filter`, search)
        .then((r) => {
          if (!r.data.error) {
            const resultData = [...r.data];
            if (!search.data_final) {
              resultData.forEach((element) => {
                element.sentido = "IDA";
              });
              this.trips = resultData;
            } else {
              resultData.forEach((element) => {
                if (element.data_viagem == search.data_final) {
                  element.sentido = "VOLTA";
                } else {
                  element.sentido = "IDA";
                }
              });
              this.trips = resultData;
            }
            this.selectedTrips = [];
            this.passagens = [];
            this.$store.dispatch("activeLoader", false);
          } else {
            this.$store.dispatch("activeLoader", false);
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "warning";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadLinhas() {
      this.$http
        .get(`/linhas`)
        .then((r) => {
          this.linhas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    selectedTravel(viagem, i) {
      if (this.selectedTrips.length <= 1) {
        if (this.selectedTrips.indexOf(viagem) == -1) {
          if (
            this.selectedTrips.length > 0 &&
            this.selectedTrips[0].data_viagem == viagem.data_viagem
          ) {
            alert(
              "A casadinha só é possível com a selecao da data de retorno!"
            );
          } else {
            this.selectedTrips.push(viagem);
            this.trips[i].selecao = "#279e4d";
            this.trips[i].indice = this.selectedTrips.length - 1;
          }
        } else {
          this.selectedTrips.splice(viagem, 1);
          this.trips[i].selecao = "rgb(12, 120, 209)";
        }
      } else if (viagem.selecao == "#279e4d") {
        this.selectedTrips.splice(this.trips[i].indice, 1);
        this.trips[i].selecao = "rgb(12, 120, 209)";
      } else {
        alert(
          "Só é possível selecionar 1 viagem em cada data! Com excessão da casadinha que são 2 datas diferentes"
        );
      }
    },
    advancePdv() {
      this.loadBus(this.selectedTrips[0]);
    },

    getBus(viagensSelecionada, returnPage) {
      this.loadBus(viagensSelecionada, returnPage);
    },
    // CHECAR SE PASSAGENS ESTAO COMPLETAS
    checarPassagens() {
      let tempTickets = [];
      for (let index = 0; index < this.passagens.length; index++) {
        if (
          this.passagens[index].tipoVenda != "" &&
          this.passagens[index].cliente
        ) {
          tempTickets.push(this.passagens[index]);
        }
      }

      this.mostrarTotal = tempTickets.length == this.passagens.length;
    },
    // CHECAR SE PASSAGENS ESTAO COMPLETAS
    async loadBus(selectTravel, returnPage = false) {
      this.$store.dispatch("activeLoader", true);
      const conexao = selectTravel.conexao ? selectTravel.conexao : null;
      const { data } = await this.$http.post(`/pdv/bus`, {
        id_viagem: selectTravel.id_viagem,
        id_bus: selectTravel.onibus.id,
        origem: {
          id_localidade: selectTravel.origem.id_localidade,
          id_linha: selectTravel.origem.id_linha,
          hora: selectTravel.origem.hora,
        },
        destino: {
          id_localidade: selectTravel.destino.id_localidade,
        },
        gratuidades: selectTravel.gratuidades,
        conexao,
        data: selectTravel.data_viagem,
      });

      if (
        this.idTipoVenda === "10" ||
        this.idTipoVenda === "3" ||
        this.idTipoVenda === "4"
      ) {
        this.onibus = data.map((seat) => {
          if (seat.numero && seat.gratuidade) {
            seat.unavailable = true;
          }

          return seat;
        });
      } else if (
        this.idTipoVenda === "7" ||
        this.idTipoVenda === "8" ||
        this.idTipoVenda === "9"
      ) {
        const freeSeat = data.findIndex((seat) => {
          if (
            seat.numero &&
            !seat.vendido &&
            seat.gratuidade &&
            seat.gratuidade.descricao === this.descTipoVenda
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (freeSeat >= 0) {
          this.onibus = data.map((seat) => {
            if (
              seat.numero &&
              (!seat.gratuidade ||
                seat.gratuidade.descricao !== this.descTipoVenda)
            ) {
              seat.unavailable = true;
            }

            return seat;
          });
        } else {
          this.onibus = data.map((seat) => {
            if (seat.numero && seat.gratuidade) {
              seat.unavailable = true;
            }

            return seat;
          });
        }
      } else {
        this.onibus = data;
      }

      if (!returnPage) {
        this.nextStep();
      }
      this.$store.dispatch("activeLoader", false);
    },
    loadBusUnlock(idBus, selectTravel) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/pdv/bus/${idBus}`, selectTravel)
        .then((r) => {
          if (!r.data.result) {
            this.onibus = r.data;
            this.sentido = selectTravel[0].sentido;
            selectTravel[0].sentido == "IDA"
              ? [this.$router.replace("/pdv#tab-1"), (this.tabs = "#tab-1")]
              : selectTravel[0].sentido == "VOLTA"
              ? [this.$router.replace("/pdv#tab-2"), (this.tabs = "#tab-2")]
              : null;
            this.$store.dispatch("activeLoader", false);
          } else {
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "warning";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.loadTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    getClient(id) {
      this.$http.get(`/pdv/client/${id}`).then((r) => {
        this.pdvCliente = r.data[0];
        this.$store.dispatch("activeLoader", false);
      });
    },
    formasPagamento() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.formasdePagamento = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    loadEmpresa(id) {
      this.$http
        .get(`/empresas/${id}`)
        .then((r) => {
          this.empresa = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    valorBrasileiro(v) {
      return v.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
    valorAmericano(v) {
      return v.toLocaleString("en", { minimumFractionDigits: 2 });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    // INICIO DO PROCEDIMENTO DE VALIDACAO FORMULARIO DO CLIENTE *********************************************************************************************************************
    enviarVendas() {
      this.$store.dispatch("activeLoader", true);
      let subBpe = [];
      subBpe.push({
        chBPe: this.chBPe,
        nProt: this.nProt,
        tpAmb: this.tpAmb,
        estado: this.estado,
        idBpe: this.idBpe,
      });
      this.$http
        .post(`/vendas/remarcar`, { passagens: this.passagens, subBpe })
        .then((r) => {
          if (r.data.result) {
            this.$store.dispatch("activeLoader", false);
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "success";
            setTimeout(() => {
              this.$router.push("/pdv");
            }, 2000);
          } else {
            this.$store.dispatch("activeLoader", false);
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "error";
            setTimeout(() => {
              this.$router.push("/pdv");
            }, 2000);
          }
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
          // setTimeout(() => {
          //   location.reload(1);
          // }, 2000);
        });
    },
    checkCashboxOpen() {
      this.$http
        .post(`agencias/check-cashbox-open`, {
          agency_id: this.$store.getters.getOpAgency,
          operator_id: this.$store.getters.getOperador,
        })
        .then((response) => {
          if (!response.data.status) {
            if (this.$store.getters.getOperadorLevel >= 5) {
              this.showModal = true;
              this.$router.replace("/pdv/financeiro/caixa-bilheteiro");
            }
            return;
          }
        });
    },
    calculateChange() {
      const amount = this.valorPago.replace(".", "");

      return (parseFloat(amount) - parseFloat(this.valorFinal)).toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 }
      ) != "NaN"
        ? (parseFloat(amount) - parseFloat(this.valorFinal)).toLocaleString(
            "pt-br",
            { minimumFractionDigits: 2 }
          )
        : "0,00";
    },
  },
};
</script>

<style lang="css">
.fontGray input {
  color: #787878;
}
.lines {
  padding: 20px;
  text-align: center;
}
.travels {
  display: flex;
  flex-direction: row;
  border: 1px solid #eee;
}
.travels .primeira {
  background: #fbfbfb;
  width: 100%;
  padding: 5px;
  border: 1px solid #eee;
  color: #808080;
}
.travels .segunda {
  width: 100%;
  padding: 5px;
  border: 1px solid #eee;
  color: #808080;
}
.travels ul {
  list-style-type: none;
}
.travels .dados li {
  margin-right: 10px;
}
.travels .dados ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.travels .dados .ulp {
  display: inline-block;
}
.travels .dados {
  width: 80%;
  padding: 10px;
}
.travels .selecao {
  width: 20%;
  color: honeydew;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 4px dashed rgb(218, 217, 217);
}
.texts {
  font-size: 1.1rem;
  color: gray;
}
.desconto_tag {
  position: absolute;
  right: 0;
  top: 0;
  background: #eee;
  padding: 3px;
  color: gray;
  border-bottom-left-radius: 6px;
}
.fil1 {
  fill: #fff;
}
</style>
